import React from "react";
import PropTypes from 'prop-types';

export const InputField = (props) => {
    const {isArea, label, icon, setFn, val, inName}= props
    return (
        <div className="input-field">
          <i className="material-icons prefix">{icon}</i>
          {isArea && 
                <textarea
                id={inName}
                name={inName}
                className="materialize-textarea"
                value={val}
                onChange={(evt)=>setFn(evt.target.value)}
              />
          }
          {!isArea && 
            <input
                id={inName}
                name={inName}
                type= 'text'
                className="validate"
                required="required"
                value={val}
                onChange={(evt)=>setFn(evt.target.value)}
            />
          }
          <label htmlFor="tboxName">{label}</label>
        </div>
    )
}

InputField.prototype = {
    isArea:  PropTypes.bool,
    icon: PropTypes.string,
    label: PropTypes.string,
    val: PropTypes.string,
    setFn: PropTypes.func,
    inName: PropTypes.string,
}