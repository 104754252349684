import React from "react";
import { Carousel } from "react-materialize";
import Project from "../../components/Project/Project"
import "./Representation.css";

export default function Representation() {  
  const detailArray = [
    `<p><b>Problem:</b> Antiquated site with limited capabilities had a local company trapped in the past with little chance of expansion and vulnerable to attack due to serious security flaws.</p>
    <p><b>Solution:</b> Deployed a modern responsive site with password hashing, secured certificates. The site provided limited support for just vanity tops for a limited number of stores to a single vendor to allowing HomeDepot, Lowe's, Meranrd's and numerous other independent contractors to place orders on vanity tops, showers and shelving. Historical pricing allows for accurate orders and sale pricing that can be set months in advance.</p>`,
    
    `<p><b>Problem:</b> Your old .Net POS software is in serious need of an upgrade.</p>
    <p><b>Solution:</b> You build a new universal POS system. As part of a team I helped develop the next generation POS. The system could be customized for mutli-lingual clients, handle color blind spectrums, left handed users and take various forms of payments, both traditional and electronic. The POS interacted with a variety of hardware including cash drawers, scales, pin-pads, printers, scanners, MICR's. I worked with loyalty programs, payments, government programs and taxes.</p><p>The backend connected to over a dozen microservices managing the cart, catalogs, discounts, coupons, receipts and more. `,
    
    `<p><b>Problem:</b> Company leadership couldn't calculate monthly commissions without spending hours, exporting and compiling excel spreadsheets.</p>
    <p><b>Solution:</b> By getting access to the back end of ConnectWise, I developed a dashboard that allows users to review all sales they have completed for any given date range. It breaks down the sales based on the payment of the invoice, the type of sale, the company and the product. The commissions levels of sales change based on who the sales manager and originator are as well as total dollar figures sold by the manager. Sales resulting in a loss lower commissions. The sales managers can see and export their own data to an excel spreadsheet. The sales directors can view the progress of any of their direct reports.</p>
    <p>Due to the sensitive information within the application, the real application can not be viewed although redacted screenshots can be seen by clicking on the interaction image.</p>    `,
    
    `<p><b>Problem:</b> The sales team needed a clear way to look at the tech support team and determine what assets were available at any given day.</p>
    <p><b>Solution:</b> The backend database with ConnectWise drives data in this interaction. API calls get lists of all opportunities in the sales cycle and lists them out in order showing the most promising and immediate items first.</p>
    <p>Each opportunity is listed out with important details related to the scheduling of the event. People driving the project are linked for email and phone numbers are also listed. Important documents linked to the opportunity are also accessible through the interface.</p>
    <p>By clicking on the calendar icon, a chart is displayed showing people scheduled to work on the project. It shows the capacity level for the teams and lets the sales force know when resources are available and allow them to better schedule events. This has reduced double bookings of resources and the need to reschedule and delay services on new projects.</p> `,
  
    `<p><b>Problem:</b> Keeping up with multiple pages of character sheets is annoying</p>
    <p><b>Solution:</b> I built an online version of Pathfinder character sheets that update stats, to hits, AC's as your character progresses. It tracks skills, feats, spells, expendable items like potions and scrolls, calculates weight and encumbrance. I added notes sections to track in game information. Users can pin commonly viewed data to an overview page to allow for less page requests within the application.</p>`,   

    `<p><b>Problem:</b> Calculating a tip can be simple enough but what happens when you have a large party.</p>
    <p><b>Solution:</b> This simple javascript application allows you to split bills and calculate tips while including or excluding taxes. The bill can be evenly divided or you can itemize to multiple people.</p>`    
    
  ];

  const showModal = title => {
    const modal = document.getElementById("detailmodal");
    const gitHubStart = `<p><strong>GitHub:</strong> <a target="_blank" href=`;
    const gitHubEnd = `>Click Here</a>`;
    let details='';
    let urlString='';
    modal.className += " modalOpen";
    const contentHolder = document.getElementById("details");
    if(title.startsWith('Connectwise Commission')){
      details = detailArray[2];
    }else if(title.startsWith('Connectwise Capacity')){
      details = detailArray[3];
    }else if(title.startsWith('Tip')){
      details = detailArray[5];
    }else if(title.startsWith('Vanity Manufacturer')){
      details = detailArray[0];
    }else if(title.startsWith('NCR')){
      details = detailArray[1];
    }else if(title.startsWith('Online Character')){
      details = detailArray[4];
    }
    contentHolder.innerHTML = details;
  }
  const hideModal = () =>{  
    let modal = document.getElementById("detailmodal");
    modal.className = modal.className.replace("modalOpen", "");
  }

  return (
    <div className="mainContent col">     
      <h4 className="repHeader">Representation</h4>
    {/* MODAL WINDOOW */}
      <div id="detailmodal" className="modal center-align">
        <div className="modal-content">   
          <div id="details">    
           
          </div>   
        </div>
        <div className="modal-footer">
          <button className="btn-small" onClick={hideModal}>
            Close
          </button>
        </div>
       
      </div>
      <Carousel options={{ fullWidth: true, indicators: true }} className="center col m11 l7">
        <Project showModal={showModal} stack="reactSQLStack.png" tech="Node.js, express, SQL, React" img="marcraft.png" title="Vanity Manufacturer" link="https://store.marcraftusa.com"/>
        <Project showModal={showModal} stack="ncrStack.png" tech="Node.js, express, TypeScript, React" img="pos-icon-2.jpg" title="NCR" />
        <Project showModal={showModal} stack="cSharp.png" tech="C#, HTML5, Bootstrap, Sql Server" img="commDashboardTile.png" title="Connectwise Commission Report" link="https://krtcotmo2.github.io/csharpexample/"/>
        <Project showModal={showModal} stack="cSharpJs.png" tech="C#, HTML5, Bootstrap, Sql Server" img="ocdTile.png" title="Connectwise Capacity Charts" link="https://krtcotmo2.github.io/csharpexample/ocd.html"/>
        <Project showModal={showModal} stack="ncrStack.png" tech="Node.js, express, TypeScript, React" img="char-sheets.png" title="Online Character Sheets" link="https://characters.kurtisc.com/"/>
        <Project showModal={showModal} stack="reactJS.png" tech="React, JavaScript" img="evenSt.png" title="Tip Calculator" link="http://krc-tip-calc.s3-website-us-east-1.amazonaws.com/"/>
      </Carousel>

      <img className="mernQuad hide-on-med-and-down" alt="MERN Stack" src="./images/mernQuads.png" />
      <div className="tagLine">React</div>

      
    </div>
  );
}
