import React, { useState, useRef } from "react";
import "./Network.css";
import { InputField } from "./input-field";
import emailjs from '@emailjs/browser';

export default function Network() {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [msg, setMsg] = useState('');
  const [open, setOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState('');
  const form = useRef();


  const handleSubmit = event => {
    event.preventDefault();
    //validation
    if(name.trim() === ''){
      setOpen(true);
      setModalMsg('Please include your name');
      return;
    }
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!regex.test(email.toLowerCase())) {
      setModalMsg('Please include a valid email address');
      setOpen(true);
      return;
    }
    if(msg.trim() === ''){
      setModalMsg('Please include your message');
      setOpen(true);
      return;
    }
  
    sendFeedback();
  };

  const sendFeedback = (
  ) => {  
    emailjs.sendForm(
      process.env.REACT_APP_SERVICEID,
      process.env.REACT_APP_MAILTEMPLATE,
      form.current,
      {
        publicKey: process.env.REACT_APP_PUBKEY
      }).then(
        () => {
          setModalMsg('SUCCESS!');
          setEmail('');
          setName('');
          setMsg('');
          setOpen(true);
        },
        (error) => {
          setModalMsg('FAILED...', error.text);
          setOpen(true);
        },
        
      );
    
  };

  return (
    <div className="mainContent col">
      <h4>Networking</h4>
      <form ref={form} className="col m12 l7" id="contactMe" action="contact">
        <InputField icon='person' setFn={setName} label='Full Name' isArea={false} val={name} inName='name'/>
        <InputField icon='email_circle' setFn={setEmail} label='Email' isArea={false} val={email} inName='email'/>
        <InputField icon='insert_comment' setFn={setMsg} label='Message' isArea={true} val={msg} inName='msg'/>
        <div className="center-align">
          <button type="submit" className="waves-effect waves-light btn" onClick={handleSubmit} >
            Submit
          </button>
        </div>
        
        <div className="show-on-medium-and-down " style={{paddingTop:"32px", display:"none"}}>
          <div className="col s6 center-align">
            <a href="https://www.linkedin.com/in/kurtcooney/" target="_blank"><img src="./images/linkedin.png"/></a>
          </div>
          <div className="col s6 center-align">
            <a href="https://github.com/krtcotmo2" target="_blank"><img src="./images/gitHub.png"/></a>
          </div>
        </div>
      </form>
     
      <div className="mernQuad hide-on-med-and-down">
        <div className="row">
          <img alt="MERN Stack" src="./images/mernQuads.png" />
        </div>
        <div className="row">
          <div className="col s6 center-align"><a href="https://www.linkedin.com/in/kurtcooney/" target="_blank"><img src="./images/linkedin.png"/></a></div>
          <div className="col s6 center-align"><a href="https://github.com/krtcotmo2" target="_blank"><img src="./images/gitHub.png"/></a></div>
        </div>
      </div>
      <div className="tagLine">Node js</div>

      {open && 
        <div id="modal1" className="modalOpen modal center-align">
          <div className="modal-content">
            <p id='errMsg'>{modalMsg}</p>
          </div>
          <div className="modal-footer ">
            <button className="btn white waves-effect waves-green darken-2 btn-flat" onClick={()=>setOpen(false)}>
              OK
            </button>
          </div>
        </div>
      }
    </div>
  );
}
